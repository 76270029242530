import React, { Component } from "react";
import "./CategoryVideo.css";

export class CategoryVideo extends Component {
  state = {
    showVideo: false,
  };

  render() {
    const { sources, headerBackgroundImage, nextCategoryButtonLabel } =
      this.props;
    const { showVideo } = this.state;

    return (
      <div className={"category-video-container"}>
        {headerBackgroundImage && (
          <header className="header">
            <img src={headerBackgroundImage} alt="" />
          </header>
        )}
        <video
          autoPlay
          muted
          onEnded={() => this.setState({ showVideo: true })}
        >
          {sources.map((source, index) => (
            <source
              key={index}
              src={source.source}
              type={source.type ? source.type : "video/mp4"}
            />
          ))}
        </video>
        {showVideo && (
          <button
            className={"next-category"}
            onClick={() => this.props.nextCategory()}
          >
            {nextCategoryButtonLabel}
          </button>
        )}
      </div>
    );
  }
}

export default CategoryVideo;
