import React, { Component } from "react";
import { formatMoney } from "../../util/utils";
import gsap from "gsap";

// import "bootstrap/dist/css/bootstrap.min.css";

export class ComparableItem extends Component {
  componentDidMount() {
    if (this.props.isMultipleItemsState === true) return;

    // gsap.to(".comparable-item", {
    //   x: 0,
    //   opacity: 1,
    //   ease: "power2.out",
    // });
    gsap.to(".stickyQrCode", {
      x: 0,
      opacity: 1,
      ease: "power2.out",
    });
    gsap.to(".header > .title", {
      y: 0,
      opacity: 1,
      delay: 0.8,
      ease: "power2.out",
    });
    gsap.to(".header > .subtitle", {
      y: 0,
      opacity: 1,
      delay: 0.8,
      ease: "power2.out",
    });

    gsap.from(".content > .image", {
      x: -60,
      opacity: 0,
      delay: 1,
      ease: "power2.out",
    });

    gsap.to(".content > .body", {
      x: 0,
      opacity: 1,
      delay: 1,
      ease: "power2.out",
    });
    gsap.to(".comparable-item > .footer-container > .col-auto", {
      y: 0,
      opacity: 1,
      delay: 1.2,
      ease: "power2.out",
    });
    gsap.to(".footer > .price-label ", {
      y: 0,
      opacity: 1,
      delay: 1.3,
      ease: "power2.out",
    });
    gsap.to(".footer > .price ", {
      y: 0,
      opacity: 1,
      delay: 1.4,
      ease: "power2.out",
    });
    gsap.to(".footer > .currency ", {
      x: 0,
      opacity: 1,
      delay: 1.5,
      ease: "power2.out",
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.productListArray !== prevProps.productListArray) {
      gsap.fromTo(
        ".content > .image",
        { x: -60, opacity: 0, delay: 1, ease: "power2.out" },
        { x: 0, opacity: 1, delay: 1, ease: "power2.out" }
      );
      gsap.fromTo(
        ".header > .title",
        { y: -20, opacity: 0, delay: 0.8, ease: "power2.out" },
        { y: 0, opacity: 1, delay: 0.8, ease: "power2.out" }
      );
      gsap.fromTo(
        ".header > .subtitle",
        { y: 20, opacity: 0, delay: 0.8, ease: "power2.out" },
        { y: 0, opacity: 1, delay: 0.8, ease: "power2.out" }
      );
      gsap.fromTo(
        ".content > .body",
        { x: 60, opacity: 0, delay: 1, ease: "power2.out" },
        { x: 0, opacity: 1, delay: 1, ease: "power2.out" }
      );
      gsap.fromTo(
        ".comparable-item > .footer-container > .col-auto",
        { y: "100%", opacity: 0, delay: 1.2, ease: "power2.out" },
        { y: 0, opacity: 1, delay: 1.2, ease: "power2.out" }
      );
      gsap.fromTo(
        ".footer > .price-label",
        { y: -10, opacity: 0, delay: 1.3, ease: "power2.out" },
        { y: 0, opacity: 1, delay: 1.3, ease: "power2.out" }
      );
      gsap.fromTo(
        ".footer > .price ",
        { y: 10, opacity: 0, delay: 1.4, ease: "power2.out" },
        { y: 0, opacity: 1, delay: 1.4, ease: "power2.out" }
      );
      gsap.fromTo(
        ".footer > .currency ",
        { y: 10, opacity: 0, delay: 1.5, ease: "power2.out" },
        { y: 0, opacity: 1, delay: 1.5, ease: "power2.out" }
      );
      gsap.fromTo(
        ".stickyQrCode",
        { x: "100%", opacity: 0, ease: "power2.out" },
        { x: 0, opacity: 1, ease: "power2.out" }
      );

      gsap.fromTo(
        ".comparable-item",
        { x: "-150%", opacity: 1, ease: "power2.out", visibility: "hidden" },
        {
          x: 0,
          opacity: 1,
          visibility: "visible",
          ease: "power2.out",
          duration: 0.5,
          stagger: 0.3,
        }
      );
    }
  }

  render() {
    const {
      item,
      index,
      backgroundImageUrl,
      priceLabel,
      currency,
      isMultipleItemsState,
      comparatorItemsVideo,
    } = this.props;
    let backgroundImage;

    if (isMultipleItemsState) {
      // if (index > 0) {
      //   gsap.to(".multiple-item-1,.multiple-item-2", {
      //     x: 0,
      //     duration: 0.5,
      //     opacity: 1,
      //     ease: "power2.out",
      //   });
      // }

      backgroundImage = item.backgroundImage
        ? item.backgroundImage.contentUrl
        : backgroundImageUrl;
    } else {
      backgroundImage = item.singleBackgroundImage
        ? item.singleBackgroundImage.contentUrl
        : backgroundImageUrl;
    }
    const attributeValues = !item.attributeValues
      ? []
      : item.attributeValues.sort((a, b) => {
          if (a.attribute.position < b.attribute.position) {
            return -1;
          }
          if (a.attribute.position > b.attribute.position) {
            return 1;
          }
          return 0;
        });

    const getValue = (av) => {
      switch (av.attribute.type) {
        case "single_value":
          return { type: av.attribute.type, value: av.value };
        case "multiple_values":
        case "colors":
          return { type: av.attribute.type, values: av.values };
        default:
          return { type: av.attribute.type, value: av.value };
      }
    };

    if (item.video && item.videoFullScreen === true) {
      // console.log(
      //   "item.video,comparatorItemsVideo",
      //   item.video["@id"],
      //   comparatorItemsVideo
      // );
      const videoUrl = comparatorItemsVideo.find(
        (video) => video.id === item.video["@id"]
      );
      // console.log("videoUrlss", videoUrl);
      return (
        <div className="video-fullscreen">
          <video autoPlay loop src={videoUrl?.url} />
        </div>
      );
    }
    console.log("attributeValues", attributeValues, item);

    return (
      <div
        className={`comparable-item col ${
          isMultipleItemsState ? `multiple multiple-item-${index}` : "single"
        }`}
        style={{ backgroundImage: "url(" + backgroundImage + ")" }}
      >
        {/* <strong style={{ fontSize: "1rem" }}>{JSON.stringify(item.uid)}</strong> */}
        <div className="row justify-content-md-center">
          <div className="header col-auto">
            <h1 className={"title"}>{item.title}</h1>
            <h2 className={"subtitle"}>{item.subtitle}</h2>
          </div>
        </div>

        <div className="content">
          {item.image && (
            <div className="image">
              <img src={item.image.contentUrl} alt="" />
            </div>
          )}
          {item.video && (
            <div className="video">
              <video autoPlay loop src={item.video.contentUrl} />
            </div>
          )}
          {/*<div className="body" dangerouslySetInnerHTML={{ __html: item.body }}></div>*/}

          <div className="body">
            {attributeValues.length > 0 ? (
              <ul>
                {attributeValues.map((av) => {
                  const item = getValue(av);
                  return (
                    <li key={av.attribute.name}>
                      {av.attribute.icon && av.attribute.icon.contentUrl && (
                        <img
                          src={av.attribute.icon.contentUrl}
                          alt={av.attribute.name}
                          className="attribute-icon"
                        />
                      )}
                      <span>{av.attribute.name}</span>
                      {item.type === "colors" ? (
                        <p className="colors">
                          {item.values.map((v) => (
                            <span style={{ backgroundColor: v.value }}></span>
                          ))}
                        </p>
                      ) : item.type === "multiple_values" ? (
                        <p>
                          {item.values.map((v, i) => (
                            <span>
                              {v.value}
                              {item.values.length - 1 !== i ? ", " : ""}
                            </span>
                          ))}
                        </p>
                      ) : (
                        <p>{item.value}</p>
                      )}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
            )}
          </div>
        </div>
        <div className="footer-container row justify-content-center no-gutters">
          <div className="col-auto">
            <div className="footer">
              <span className="price-label">{priceLabel}</span>
              <p className="price">{formatMoney(item.price, 2, ".", " ")}</p>
              <p className="currency">{currency}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComparableItem;
