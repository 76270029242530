import PropTypes from "prop-types";

export default function Slide({ title, video, photo }) {
  const renderImgInCorrectDimension = ({ target: img }) => {
    if (img.naturalHeight > 0 && img.naturalWidth > 0) {
      const maxWidth = window.clientWidth;
      const maxHeight = window.clientHeight;
      fixAspectRatio(img, maxWidth, maxHeight);
    }
  };

  const handleVideo = ({ target: videoElement }) => {
    setVidDimension(videoElement);
    if (videoElement.paused) {
      videoElement.play();
      videoElement.addEventListener("ended", () => {
        videoElement.play();
      });
    }
  };

  const setVidDimension = (vid) => {
    if (vid.videoHeight > 0 && vid.videoWidth > 0) {
      const maxWidth = window.clientWidth;
      const maxHeight = window.clientHeight;
      fixAspectRatio(vid, maxWidth, maxHeight);
    }
  };

  const fixAspectRatio = (element, maxWidth, maxHeight) => {
    if (element.videoHeight > element.videoWidth) {
      const ratio = maxHeight / element.videoHeight;
      const width = ratio * element.videoWidth;
      element.style.height = maxHeight;
      element.style.width = width + "px";
    } else {
      const ratio = maxWidth / element.videoWidth;
      const height = ratio * element.videoHeight;
      element.style.width = maxWidth;
      element.style.height = height + "px";
      if (height > 0) {
        element.style.marginTop = (maxHeight - height) / 2 + "px";
      }
    }
    return element;
  };

  return (
    <>
      {title && <div className="title">{title}</div>}
      {video && (
        <video muted onLoadedMetadata={handleVideo} controls>
          <source src={video.contentUrl} type="video/mp4" />
        </video>
      )}
      {!video && photo && (
        <img
          onLoad={renderImgInCorrectDimension}
          src={photo.contentUrl}
          alt={title}
        />
      )}
    </>
  );
}

Slide.propTypes = {
  photo: PropTypes.object,
  title: PropTypes.string,
  video: PropTypes.object,
};
